import React, { useState } from 'react';
import './App.scss';
const classNames = require('classnames');

function App() {
    const [more, setMore] = useState(false);
    const moreClasses = classNames({
        expand: true,
        expanded: more
    })
    const detailsClasses = classNames({
        details: true,
        expanded: more
    })
    return (
        <div className="App">
            <header className="App-header">
                <img src="/android-chrome-512x512.png" className="App-logo" alt="logo" />
                <h1>
                    Agilissimo&nbsp;AB
                </h1>
                <p>
                    We provide consultancy services related to software engineering. Contact us for more information about our services.
                </p>

                <a href="mailto:info@agilissimo.se">info@agilissimo.se</a>
                <a className="tel" href="tel:+46841082099">+46 8 41082099</a>

                <div className="details-wrapper">
                    <span className={moreClasses} onClick={() => setMore(!more)} rel="nofollow">more...</span>
                    <div className={detailsClasses}>
                        <p className="detail-header">Postal address</p>
                        <p className="detail-info">
                            Agilissimo AB<br/>
                            Vikingshillsvägen 18<br/>
                            132 37 Saltsjö-Boo<br/>
                            Sweden
                        </p>
                        <p className="detail-header">VAT No</p>
                        <p className="detail-info">SE559239905801</p>
                    </div>
                </div>

            </header>
        </div>
    );
}

export default App;
